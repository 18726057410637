@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import the global styles from your custom CSS */
@import './global.css';

.scrollable-paragraph {
    max-height: 240px; /* Adjust this value as needed */
    overflow-y: auto;  /* Enable vertical scrolling */
  }
  
